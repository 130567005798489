<template>
  <div>
    <div class="white--text text-center">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="notificationType"
        top
      >
        {{ notificationText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <div class="white--text">
              Close
            </div>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div v-if="friend && !loading">
      <v-img class="cover-image"
      :src=" friend.profile_background_image ? friend.profile_background_image
      : require(`@/assets/Mask Group 6.png`)"
      >
      </v-img>
      <section class="content-section">
        <div>
          <v-container>
            <div class="profile-photo mb-5">
              <div class="avatar-container">
                  <v-avatar  size="200" :color="friend.profile_photo ? 'white' : 'primary'" class="text-bold text-capitalize white--text heading">
                      <v-img v-if="friend.profile_photo" :src="friend.profile_photo"></v-img>
                      <span v-else-if="friend.full_name">{{friend.full_name.charAt(0)}}</span>
                      <span v-else>{{ friend.user_name.charAt(0)}}</span>
                  </v-avatar>
              </div>
            </div>
              <v-row class="mt-2" justify="space-between" align="center">
                  <div>
                      <p class="full-name text-center primaryDark--text text-bold heading">{{ friend.full_name }}</p>
                      <p class="body-1 primaryDark--text" v-if="friend.about_me"> ({{friend.about_me}})</p>
                      <p class="text-center primaryDark--text">{{ friend.email }}</p>
                  </div>
                  <div>
                      <v-btn @click="addFriend" v-if="friend.friend_status === 'not_friend'" elevation="0" class="mb-3 rounded-lg" color="primary" large>Send Friend Request</v-btn>
                      <div v-else>
                          <v-btn @click="cancelFriend" v-if="friend.friend_status === 'request_add_friend'" elevation="0" class="mb-3 px-10  mr-5 rounded-lg" color="error" large>reject</v-btn>
                          <v-btn @click="confirmRequest" v-if="friend.friend_status === 'request_add_friend'" elevation="0" class="mb-3 px-10 rounded-lg" color="success" large>accept</v-btn>
                      </div>
                  </div>
              </v-row>
          </v-container>
        </div>
        <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-card outlined>
                    <v-card-title class="font-weight-bold  primary--text">Profile Info</v-card-title>
                    <v-row>
                      <v-col cols="12" md="4" class="avatar-container pa-5">
                        <v-img max-width="300" v-if="friend.avatar" :src="friend.avatar"></v-img>
                        <v-avatar v-else :size="180" :color="friend.avatar ? 'white' : 'primary'" class="text-bold text-capitalize white--text heading">
                          <span v-if="friend.full_name">{{friend.full_name.charAt(0)}}</span>
                          <span v-else>{{ friend.user_name.charAt(0)}}</span>
                        </v-avatar>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-simple-table full-width>
                            <template v-slot:default>
                                <tbody>
                                    <tr v-if="friend.nick_name">
                                        <td class="font-weight-medium">Dispaly Name</td>
                                        <td>{{ friend.nick_name }}</td>
                                    </tr>
                                    <tr v-if="friend.slogan">
                                        <td class="font-weight-medium">Slogan</td>
                                        <td>{{ friend.slogan }}</td>
                                    </tr>
                                    <tr v-if="friend.gender">
                                        <td class="font-weight-medium">Gender</td>
                                        <td>{{ friend.gender }}</td>
                                    </tr>
                                    <tr v-if="friend.full_name">
                                        <td class="font-weight-medium">Name</td>
                                        <td>{{ friend.full_name }}</td>
                                    </tr>
                                    <tr v-if="friend.date_of_birth">
                                        <td class="font-weight-medium">Birth Date</td>
                                        <td>{{ new Date(friend.date_of_birth).toDateString() }}</td>
                                    </tr>
                                    <tr v-if="friend.marital_status">
                                        <td class="font-weight-medium">Marital Status</td>
                                        <td>{{ friend.marital_status }}</td>
                                    </tr>
                                    <tr v-if="friend.home_country">
                                        <td class="font-weight-medium">Home City</td>
                                        <td>{{ friend.home_city }}, {{ friend.home_country }}</td>
                                    </tr>
                                    <tr v-if="friend.current_country">
                                        <td class="font-weight-medium">Current City</td>
                                        <td>{{ friend.current_city }}, {{ friend.current_country }}</td>
                                    </tr>
                                    <tr v-if="friend.current_address">
                                        <td class="font-weight-medium">Address</td>
                                        <td>{{ friend.current_address }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card outlined>
                  <v-card-title class="font-weight-bold py-0 primary--text">Interests</v-card-title>
                  <div class="pa-3 d-flex justify-space-between flex-wrap" v-if="friend.tags.length">
                    <v-checkbox
                    v-for="item in friend.tags"
                    :key="item.id"
                    hide-details
                    v-model="checked"
                    readonly
                    :value="true"
                    class="mr-3"
                    >
                      <template v-slot:label>
                        {{item.name}}
                        <v-rating
                        background-color="primary lighten-1"
                        color="primary"
                        empty-icon="mdi-star-outline"
                        full-icon="mdi-star"
                        length="5"
                        readonly
                        size="15"
                        dense
                        :value="item.pivot.rank ? item.pivot.rank : 1"
                        ></v-rating>
                      </template>
                    </v-checkbox>
                  </div>
                  <p v-else class="primary--text text-center">No Interests !</p>
                </v-card>
              </v-col>
            </v-row>

            <v-card-title class="primary--text font-weight-bold">Trips</v-card-title>

            <v-card>
              <v-tabs
              class="mb-10"
              v-model="tab"
              background-color="transparent"
              color="primary"
              grow
              >
                <v-tab>
                  Photos
                </v-tab>
                <v-tab>
                  Videos
                </v-tab>
              </v-tabs>
            </v-card>

            <div v-if="!tab">
              <div class="d-flex flex-wrap">
                <v-img class="rounded-lg ma-1" height="150" max-width="200" v-for="(image, j) in images" :key="j" :src="image"></v-img>
              </div>
              <!-- <div v-else class="text-center">
                <v-icon color="primary" size="100">mdi-image-multiple-outline</v-icon>
              </div> -->
            </div>
            <div v-else class="text-center">
              <v-icon color="primary" size="100">mdi-video-outline</v-icon>
            </div>
        </v-container>
      </section>
    </div>
    <p class="text-h5 my-5 primary--text" v-else-if="!friend && !loading">
        The User Data isn't available!
    </p>
  </div>
</template>

<script>
import user from '@/web_services/user'

export default {
  data () {
    return {
      friend: null,
      allQuestions: [],
      loading: true,
      snackbar: false,
      notificationText: null,
      timeout: 3000,
      tab: 0,
      notificationType: null,
      checked: true
    }
  },
  computed: {
    images () {
      const images = []
      if (this.friend) {
        this.friend.memories.forEach(item => {
          item.memory_images.forEach(image => {
            images.push(image.name)
          })
        })
      }
      return images
    }
  },
  methods: {
    addFriend () {
      user.addFriend(this.$route.params.id).then((res) => {
        this.getUser()
        this.showNotification('success', 'Friend Request is sent')
      }).catch(() => {
        this.showNotification('error', 'Couldnot send the request')
      }).finally(() => {
        this.loading = false
      })
    },
    confirmRequest () {
      user.confirmFriend(this.$route.params.id).then((res) => {
        this.showNotification('success', 'You are now friends')
        this.getUser()
      }).catch(() => {
        this.showNotification('error', 'Couldnot complete the request')
      }).finally(() => {
        this.loading = false
      })
    },
    cancelFriend () {
      user.cancelFriend(this.$route.params.id).then((res) => {
        this.showNotification('success', 'Friend Request is rejectd')
        this.getUser()
      }).catch(() => {
        this.showNotification('error', 'Couldnot complete the request')
      }).finally(() => {
        this.loading = false
      })
    },
    getUser () {
      user.friendDetails(this.$route.params.id).then(res => {
        this.friend = res.data.data
      }).catch(err => {
        this.showNotification('error', err.data.message)
      }).finally(() => {
        this.loading = false
      })
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationText = text
      this.notificationType = color
    }
  },
  created () {
    this.getUser()
    user.interestQuestions().then(res => {
      this.allQuestions = res.data.data
    }).catch(error => {
      this.$emit('error', error)
    })
  }
}

</script>

<style>
.profile {
  height: 400px;
  background: -webkit-image-set(url('../assets/images/home-sm.jpg') 1x, url('../assets/images/home-lg.jpg') 2x);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  overflow-y: visible;
}

.profile-photo {
  width: fit-content;
}

.content-section {
  transform: translateY(-100px);
}

.v-text-field--outlined fieldset {
  border-color: #cecece !important;
  border-width: 0.5px;
}

.cover-image {
  max-width: 100%;
  height: 400px;
}

.cover-button {
  z-index: 1;
}

.user-slogan {
  text-align: center;
  position: absolute;
  top: 140px;
  left: 280px;
  font-size: 40px;
}
.full-name {
  font-size: 45px;
}
</style>
